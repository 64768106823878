// import i18n from '@/i18n/index'

export default {
  tableDataItem: [
    { prop: 'id', label: '', Object: 'value', width: '120' },
    { prop: 'charge_point', label: '', Object: 'value', width: '150' },
    { prop: 'display_max_power', label: '', Object: 'value', width: '200' },
    { prop: 'phase', label: '', Object: 'value', width: '120' },
    { prop: 'phase_rotation', label: '', Object: 'value', width: '180' },
    { prop: 'input_voltage', label: '', Object: 'value', width: '150' },
    { prop: 'max_current', label: '', Object: 'value', width: '150' }
  ]
}
